<template>
  <v-row class="person-info-form">
    <v-col cols="4">
      <div class="image-container" @click="$refs.inputFile.click()">
        <input
            class="d-none"
          accept="image/*"
          type="file"
          ref="inputFile"
          @change="imageChanged"
        />
        <v-img :src="imagePreview" contain class="d-flex justify-center align-center" height="100%" width="100%">
          <div v-if="imagePreview == null"  class="text-center d-flex flex-column align-center justify-center">
            <v-icon>mdi-plus</v-icon>
            <small style="width: 80%;">Seleccione una foto</small>
          </div>
        </v-img>
      </div>
    </v-col>
    <v-col cols="8">
      <v-text-field
        dense
        v-model="personInfo.fullname"
        label="Nombre completo"
        outlined
      ></v-text-field>
      <v-textarea
        dense
        hide-details
        v-model="personInfo.bio"
        label="Descripción"
        outlined
        rows="3"
        no-resize
      >
      </v-textarea>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data () {
    return {
      newImageFile: null,
      newImage: null
    }
  },
  computed: {
      imagePreview(){
          if(this.newImage != null){
              return this.newImage
          }
          if(this.personInfo.photo != null){
              return this.personInfo.photo.photo_url
          }
          return null
      }
  },
  methods: {
    async imageChanged (event) {
        let file = event.target.files[0]
        this.newImageFile = file
        this.newImage = await this.imageToBase64(file)
        this.$emit('imageChanged',file)
    },
    async imageToBase64 (file) {
      return new Promise((resolve, reject) => {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = e => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          resolve(e.target.result)
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(file)
      })
    }
  },
  props: {
    personInfo: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.person-info-form {
  .image-container {
    border: 1px dotted grey;
    height: 160px;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
