<template>
  <tida-drawer
    ref="tidaDrawer"
    :title="title"
    class="drawer-form"
    :actionButton="actionButton"
    @actionPerformed="saveUser"
    :disableActionButton="!validForm"
  >
    <v-form v-model="validForm" slot="content" class="form-container">
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="px-0 py-0">
            <v-text-field
              label="Nombre completo"
              outlined
              v-model="user.fullname"
              :loading="loading"
              :rules="rules.fullname"
            />
            <v-text-field
              label="Correo electrónico"
              outlined
              type="email"
              v-model="user.email"
              :loading="loading"
              :rules="rules.email"
            />
            <v-select
              :items="$enums.ROLES"
              label="Permisos"
              multiple
              outlined
              :rules="rules.roles"
              v-model="user.roles"
            />
          </v-col>

          <div class="red--text text-center mt-8">
            {{ errorMessage }}
          </div>
        </v-row>
      </v-container>      
    </v-form>
  </tida-drawer>
</template>

<script>
import TidaDrawer from '../../shared/TidaDrawer.vue'

export default {
  components: { TidaDrawer },
  data () {
    return {
      action: '',
      errorMessage: '',
      loading: false,
      validForm: false,
      user: {
        id: this.$uid(),
        fullname: '',
        email: '',
        uid: '',
        roles: []
      },
      rules: {
        fullname:[
          v => !!v || 'Ingrese el nombre completo'
        ],
        email: [
          v => this.$validators.email(v) || 'Ingrese un correo electrónico válido'
        ],
        roles: [
          v=> this.user.roles.length>0 || 'El usuario debe tener algún rol asignado.'
        ]
      },
    }
  },
  methods: {
    reset () {
      this.user = {
        id: this.$uid(),
        fullname: '',
        email: '',
        uid: '',
        roles: []
      }
      this.action = ''
      this.errorMessage = ''
      this.loading = false
      this.$refs.tidaDrawer.stopLoading()
    },
    async saveUser () {
      this.loading = true
      try {
        if (this.action == this.$enums.ACTIONS.CREATE) {
          await this.$store.dispatch('postUser', this.user)
          this.$toast.success('Usuario creado satisfactoriamente')
        }
        if (this.action == this.$enums.ACTIONS.EDIT) {
          await this.$store.dispatch('putUser', this.user)
          this.$toast.success('Usuario editado satisfactoriamente')
        }

        this.$refs.tidaDrawer.setVisible(false)
        this.$emit('actionDone')
        this.reset()
      } catch (error) {
        this.errorMessage = error.response.data.message
        this.$refs.tidaDrawer.stopLoading()
        this.loading = false
      }
    },
    generateNewUser () {
      return {
        id: this.$uid(),
        fullname: '',
        email: '',
        uid: '',        
        roles: []
      }
    },

    open (action, user) {
      this.reset()
      this.$refs.tidaDrawer.setVisible(true)
      this.action = action
      if (action == this.$enums.ACTIONS.CREATE) {
        this.user = this.generateNewUser()
      }
      if (action == this.$enums.ACTIONS.EDIT) {
        this.user = JSON.parse(JSON.stringify(user))
      }
    }
  },

  computed: {
    title () {
      if (this.$enums.ACTIONS.CREATE == this.action) {
        return 'CREAR USUARIO'
      } else if (this.$enums.ACTIONS.EDIT == this.action) {
        return 'EDITAR USUARIO'
      }
      return ''
    },
    actionButton () {
      if (this.$enums.ACTIONS.CREATE == this.action) {
        return 'CREAR USUARIO'
      } else if (this.$enums.ACTIONS.EDIT == this.action) {
        return 'GUARDAR CAMBIOS'
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
.drawer-form {
  .form-container {
    height: 93%;
    padding: 20px 30px 0 30px;
    //background-color: #f4f9f7;
    .hint {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #818685;
    }
  }
  .dragzone {
    width: 300px;
    height: 300px;
    background: #ffffff;
    border: 2px dashed #b9c3c2;
    border-radius: 20px;
    &:hover {
      cursor: pointer;
    }
    .noImage {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      font-size: 14px;
      line-height: 24px;
      text-align: center;

      color: #818685;
      .text-select {
        font-weight: bold;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        text-decoration-line: underline;
        text-transform: uppercase;
        color: #32b975;
      }
    }
  }
}
</style>
