import Axios from "axios";
import utilities from "./utilities";

export default {
    actions:{
        async putInfographic({},payload){
            return await Axios.put('v1/admin/infographics',payload)
        },
        async postInfographic({},payload){
            return await Axios.post('v1/admin/infographics',payload)
        },
        async getInfographic({},id){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/infographics/${id}`))
        },
        async getInfographics({},params){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/infographics`,{params:params}))
        },
        async deleteInfographic({},id){
            return await Axios.delete(`v1/admin/infographics/${id}`)
        }
    }
}