<template>
  <div class="tida-member-card">
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          x-small
          class="btn-menu"
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dense>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="openEdit">
          <v-list-item-title>Editar</v-list-item-title>
        </v-list-item>
        <v-list-item @click="deleteMember">
          <v-list-item-title>Eliminar</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <div class="photo__container">
      <img :src="member.photo" />
    </div>
    <div class="member-fullname px-3">{{ member.fullname }}</div>
    <div class="member-charge px-3">{{ member.charge }}</div>
    <modal-tida-member :hideCharge="hideCharge" :includeBio="includeBio" :generateSlug="generateSlug" @done="doneEdit" ref="modalTidaMember" />
  </div>
</template>

<script>
import ImageSelector from '../shared/ImageSelector.vue'
import ModalTidaMember from '../about/ModalTidaMember.vue'
export default {
  components: { ImageSelector, ModalTidaMember },
  props: {
    member: { required: true, type: Object },
    includeBio: { required: false, type: Boolean, default: false },
    generateSlug: { required: false, type: Boolean, default: false },
    hideCharge: { required: false, type: Boolean, default: false}
  },
  methods: {
    doneEdit (member) {
      this.member.photo = member.photo
      this.member.fullname = member.fullname
      this.member.charge = member.charge
      this.member.linkedin = member.linkedin
      this.member.slug = member.slug
      this.member.bio = member.bio
    },
    openEdit () {
      this.$refs.modalTidaMember.open(this.$enums.ACTIONS.EDIT, this.member)
    },
    deleteMember () {
      this.$emit('deleteMember')
    }
  }
}
</script>

<style lang="scss">
.tida-member-card {
  border: 1px solid gray;
  position: relative;
  padding-bottom: 10px;
  .photo__container {
    img {
      position: relative;
      object-fit: fill;
      width: 100%;
      height: 250px;
    }
  }
  .member-fullname {
    font-size: 14px;
    font-weight: bold;
  }
  .member-charge {
    font-size: 14px;
  }
  .btn-menu {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
  }
}
</style>
