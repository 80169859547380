<template>
  <v-container fluid class="fill-height auth-container">
    <v-row justify="center" align="start">
      <v-col cols="12" class="text-center">
        <img :src="require('../../assets/tida-color.png')" />
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-card outlined flat>
          <v-card-text class="">
            <div class="form-title mb-10">
              {{ title }}
            </div>
            <slot name="form" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.v-label {
  font-size: 14px;
}

.auth-container {
  background: white;
  display: block !important;
  padding-top: 20px !important;
  .v-card {
    border-radius: 12px !important;
    border: none !important;
    background-color: transparent !important;
    .v-card__text {
      padding: 40px 30px 20px 30px !important;
    }
    .v-input__slot {
      border-radius: 12px;
    }
    fieldset {
      border: 1px solid #c4ced1;
      border-radius: 12px;
    }
    .form-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #343837;
    }
    label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #343837;
    }
    button {
      border-radius: 28px;
    }
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: #adafb2 !important;
      color: white !important;
    }
    .v-btn {
      color: white;
    }
    .error-text {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: #ff1a1a;
      text-align: center;
    }
    .bottom-link {
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #641A54;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
    .form-text {
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #666666;
    }
  }
  .back-button {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    color: #641A54;
    &:hover {
      cursor: pointer;
    }
  }
}
//
@media (min-width: 600px) {
  .auth-container {
    background: linear-gradient(
      180deg,
      #f2f3f2 0%,
      rgba(242, 243, 242, 0) 100%
    );
    .v-card {
      background-color: white !important;
      .v-card__text {
        padding: 60px 68px 20px 68px !important;
      }
    }
  }
}
</style>
