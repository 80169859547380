<template>
  <v-navigation-drawer class="tida-drawer" temporary :permanent="visible" width="360px" absolute right v-model="visible">
    <div class="form-header pl-8">
      <span>{{title}}</span>
    </div>
    <v-divider/>
    <slot name="content"></slot>
    <div class="form-footer">
      <v-btn small color="primary" @click="visible = false" :loading="loading" outlined>Cancelar</v-btn>
      <v-btn small :disabled="disableActionButton" color="primary" :loading="loading" @click="clickActionButton" class="mx-4">{{actionButton}}</v-btn>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      loading: false
      }
  },
  methods: {
    setVisible(visible){
      this.visible = visible
    },
    clickActionButton(){
      this.loading = true
      this.$emit('actionPerformed')
    },
    stopLoading(){
      this.loading = false
    }
  },
  props:{
    title:{
      type: String,
      required: true
    },
    actionButton:{
      type: String,
      required: true
    },
    disableActionButton:{
      type: Boolean,
      default: true
    }
  }
  
}
</script>

<style lang="scss">
.tida-drawer {
  
  .form-header {
    height: 60px;
    background-color: white;
    display: flex;
    justify-items: center;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #1f3333;
    width: 100%;
  }
  .form-footer{
    position: absolute;
    width: 100%;
    height: 72px;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    border: 2px solid #F2F3F2;
  }
}
</style>
