import firebase from "firebase/app";
import Axios from "axios";
import utilities from "./utilities";
const auth = firebase.auth();
export default {
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async loginEmail({ commit }, credentials) {
      await auth.signInWithEmailAndPassword(
        credentials.email,
        credentials.password
      );
    },
    async init({ commit }) {
      let data = await utilities.wrapResponseAndGetData(
        await Axios.get("v1/admin/profile")
      );
      commit("setUser", data);
    },
    async resetPassword({},payload){
      return auth.sendPasswordResetEmail(payload.email)
    },
    async createPassword({},payload){
      return await Axios.put('v1/public/reset-password',payload)
    },
    async verifyToken({},token){
      return utilities.wrapResponseAndGetData(await Axios.get('v1/public/reset-password',{
        params:{token:token}
      }))
    },
    async logOut({ commit }) {
      await auth.signOut();
      commit("setUser", null);
    },
  },
};
