<template>
  <v-container fluid class="px-10 news-view">
    <v-overlay color="white" :value="generalLoading">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-row wrap>
      <v-col cols="12" class="px-0">
        <v-toolbar flat>
          <v-toolbar-title class="title">
            <v-btn icon small @click="$router.push(`/columnas/${columnID}/articulos`)"
              ><v-icon>mdi-chevron-left</v-icon></v-btn
            >
            <span>{{ title }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="generalLoading" @click="saveItem"
            >GUARDAR</v-btn
          >
        </v-toolbar>
        <v-divider style="border-color: #B9C3C2;" class="" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-form v-model="valido">
          <v-row>
            <v-col cols="8">
              <v-text-field
                dense
                v-model="item.title"
                label="Título"
                outlined
              ></v-text-field>

              <v-textarea
                v-model="item.summary"
                label="Sumilla"
                no-resize
                rows="3"
                dense
                outlined
              />
              <v-row>
                <v-col cols="12" class="py-0">
                  <div class="text-subtitle-2">Datos del autor 1</div>
                  <person-info-form :personInfo="item.author" @imageChanged="authorPhotoChanged" />
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="text-subtitle-2">Datos del autor 2</div>
                  <person-info-form :personInfo="item.coauthor" @imageChanged="coauthorPhotoChanged" />
                </v-col>
              </v-row>
              <vue-editor
                :editorOptions="editorSettings"
                v-model="item.content"                
                useCustomImageHandler
                @image-added="handleImageAdded"
                :editor-toolbar="customToolbar"
              >
              </vue-editor>
            </v-col>
            <v-col cols="4">
              <v-file-input
                label="Imagen del artículo"
                outlined
                dense
                prepend-icon=""
                accept="image/*"
                @change="newFile"
                :value="imageSelected"
                :clearable="false"
              >
                {{ item.publish_date }}
              </v-file-input>
              <v-text-field
                dense
                v-model="item.photo_reference"
                label="Fuente de la imagen"
                outlined
              ></v-text-field>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedDate"
                    label="Fecha de publicación"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="es"
                  first-day-of-week="1"
                  v-model="publishDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cerrar
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <dialog-info ref="dialogInfo" />
  </v-container>
</template>

<script>
import DialogConfirm from '../../components/shared/DialogConfirm.vue'
import DialogInfo from '../../components/shared/DialogInfo.vue'
import { VueEditor, Quill } from 'vue2-editor'
import PersonInfoForm from '../../components/forms/PersonInfoForm.vue'
import htmlEditButton from 'quill-html-edit-button'
import quillDivider from 'quill-divider'
import slugify from 'slugify'
import moment from 'moment'
Quill.register({
  'modules/divider': quillDivider,
  'modules/htmlEditButton': htmlEditButton
})
export default {
  components: { DialogInfo, DialogConfirm, VueEditor, PersonInfoForm },
  computed: {
    formattedDate () {
      if (this.item.publish_date == null) {
        return 'dd/mmm/aaaa'
      }
      return moment(this.item.publish_date).format('DD/MM/YYYY')
    },
    publishDate: {
      set (date) {
        this.item.publish_date = moment(date).toDate()
      },
      get () {
        if (this.item.publish_date == null) {
          return null
        }
        console.log(moment(this.item.publish_date).format('YYYY-MM-DD'))
        return moment(this.item.publish_date).format('YYYY-MM-DD')
      }
    },
    title () {
      return this.action == this.$enums.ACTIONS.CREATE
        ? 'NUEVO ARTICULO'
        : 'EDITAR ARTICULO'
    },
    imageSelected () {
      if (this.newImage != null) {
        return this.newImage
      }
      if (this.item.photo != null) {
        return {
          name: this.item.photo.image_name
        }
      }
      return null
    }
  },
  methods: {
    authorPhotoChanged(file){
      this.authorImageFile = file
    },
    coauthorPhotoChanged(file){
      this.coauthorImageFile = file
    },
    async handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      this.generalLoading = true
      let url = await this.$files.putFileWithURL(file)
      Editor.insertEmbed(cursorLocation, 'image', url)
      this.generalLoading = false
      resetUploader()
    },
    newFile (file) {
      this.newImage = file
    },
    async saveItem () {
      this.generalLoading = true
      try {
        if (this.newImage != null) {
          let newID = await this.$files.putFile(this.newImage)
          this.item.photo = {
            image_name: this.newImage.name,
            object_id: newID
          }
        }
        if(this.authorImageFile != null) {
          let newID = await this.$files.putFile(this.authorImageFile)
          this.item.author.photo = {
            image_name: this.authorImageFile.name,
            object_id: newID
          }
        }
        if(this.coauthorImageFile != null) {
          let newID = await this.$files.putFile(this.coauthorImageFile)
          this.item.coauthor.photo = {
            image_name: this.coauthorImageFile.name,
            object_id: newID
          }
        }
        this.item.slug = slugify(this.item.title,{remove: /[*+~.()'"!:@]/g,lower: true})
        if (this.action == this.$enums.ACTIONS.CREATE) {
          await this.$store.dispatch('postColumnArticle', {
            columnID: this.columnID,
            data: this.item
          })
          this.$toast.success('Articulo creada satisfactoriamente')
        }
        
        if (this.action == this.$enums.ACTIONS.EDIT) {
          await this.$store.dispatch('putColumnArticle', {
            columnID: this.columnID,
            data: this.item
          })
          this.$toast.success('Articulo actualizada satisfactoriamente')
        }
        this.generalLoading = false
        this.$router.push(`/columnas/${this.columnID}/articulos`)
      } catch (error) {
        console.log(error)
        this.$refs.dialogInfo.open(
          'No se pudo completar la operación',
          error.response.data.error_message
        )
      }
      this.generalLoading = false
    }
  },

  data () {
    return {
      loading: false,
      valido: false,
      menu: false,
      columnID: '',
      generalLoading: false,
      newImage: null,
      dataTableOptions: {},
      loadingTable: false,
      action: '',
      rules: {
        title: [v => v.length <= 100 || 'Máximo 100 caracteres'],
        slug: [
          v => v.length <= 100 || 'Máximo 100 caracteres',
          v =>
            this.$validators.slug(v) ||
            'El título amigable debe estar en el formato correcto '
        ],
        summary: [v => v.length <= 120 || 'Máximo 120 caracteres']
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        [{ script: 'sub' }, { script: 'super' }],
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link', 'image'],
        ['divider'] // remove formatting button
      ],
      editorSettings: {
        modules: {
          htmlEditButton: {},
          divider: {
            cssText: 'border: none;border-bottom: 1px gray inset;'
          }
        }
      },
      item: {
        id: this.$uid(),
        title: '',
        summary: '',
        slug: '',
        publish_date: null,
        deleted_at: null,
        deleted_by: null,
        num_views: 0,
        photo: null,
        photo_reference: '',
        content: '',
        author: {
          fullname: '',
          bio: '',
          photo: null
        },
        coauthor: {
          fullname: '',
          bio: '',
          photo: null
        },
      }
    }
  },
  async beforeMount () {
    this.action = this.$route.query.action
    let articleID = this.$route.params.articleID
    this.columnID = this.$route.params.columnID
    this.generalLoading = true

    if (this.action == this.$enums.ACTIONS.CREATE) {
      let column = await this.$store.dispatch('getColumn',this.columnID)
      this.item = {
        id: articleID,
        title: '',
        summary: '',
        slug: '',
        publish_date: null,
        deleted_at: null,
        deleted_by: null,
        num_views: 0,
        photo: null,
        photo_reference: '',
        content: '',
        author: column.author,
        coauthor: {
          fullname: '',
          bio: '',
          photo: null
        },
      }
    } else {
      let result = await this.$store.dispatch('getColumnArticle', {
        columnID: this.columnID,
        articleID: articleID
      })
      this.item = result
    }
    this.generalLoading = false
  }
}
</script>

<style lang="scss"></style>
