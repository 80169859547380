import firebase from "firebase/app";
const storage = firebase.storage();
import { uid } from "uid";
import Vue from "vue";

var STORAGE_PATH = "https://storage.googleapis.com/asociacion-tida.appspot.com/"

if (process.env.NODE_ENV === "production") {    
  STORAGE_PATH = "https://storage.googleapis.com/asociacion-tida.appspot.com/"
}

Vue.prototype.$files = {
  async putFile(file) {
    let uidCode = uid();
    let newID = uidCode + "." + file.name.split(".").pop();
    var storageRef = storage.ref();
    var imageRef = storageRef.child(newID);
    await imageRef.put(file);
    return newID;
  },
  async putFileWithURL(file){
    let id = await this.putFile(file)
    return STORAGE_PATH + id
  }
}
