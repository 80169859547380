<template>
  <v-app>
    <loading-screen v-if="loadingScreen" />
    <router-view v-else />
  </v-app>
</template>

<script>
import LoadingScreen from './views/LoadingScreen.vue'
import firebase from 'firebase/app'
import axios from 'axios'
const auth = firebase.auth()

export default {
  name: 'App',

  components: {
    LoadingScreen
  },
  async beforeMount () {
    //auth.signOut()
    auth.onAuthStateChanged(async user => {
      if (user) {
        try {
          let newToken = await user.getIdTokenResult(true)
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + newToken.token
          await this.$store.dispatch('init')
          this.$store.commit('setLoadingScreen', false)
        } catch (error) {
          console.log(error)
          console.log(error.response)
          auth.signOut()
        }
      } else {
        this.$store.commit('setLoadingScreen', false)
        this.$store.commit('setUser', null)
      }
    })
  },

  data: () => ({
    //
  }),
  computed: {
    loadingScreen () {
      return this.$store.state.loadingScreen
    }
  }
}
</script>
<style lang="scss">
.hover:hover {
  cursor: pointer;
}
.v-toolbar__content{
  padding: 0;
}

.fill-height {
  height: 100%;
}

.error-message {
  color: #ff595f;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 15px;
}
.swal2-popup {
  font-family: Roboto;
}
.v-btn {
  //border-radius: 32px;
}
.v-input__slot {
  border-radius: 12px !important;
}
fieldset {
  border-radius: 12px;
}
.v-toast__text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
.v-toast__item--success{
  background-color: #23C333;
}
.v-toast__item{
  padding: 15px 17px 15px 5px;
  .v-toast__text{
    padding: 0;
  }
}
.v-toast__item--success .v-toast__icon {
  background: url('./assets/check-white.png') !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  margin-left: 5px;
  
}
.v-overlay--absolute{
  position: fixed;
}
.v-navigation-drawer--fixed{
  z-index: 5;
}
.v-data-table__wrapper {
    table {
      tbody {
        tr:nth-child(even) {
          td {
            background: transparent;
          }
        }
        tr:nth-child(odd) {
          background: #F9F8FF;
        }
        tr {
          &:hover {
            background-color: #F5EFFF !important;
          }
        }
      }
    }
  }

.ql-container{
  height: 500px !important;
}
</style>
