import Axios from "axios";
import utilities from "./utilities";

export default {
    actions:{
        async putColumnArticle({},payload){
            let columnID = payload.columnID
            let data = payload.data
            return await Axios.put('v1/admin/columns/'+columnID+"/articles",data)
        },
        async postColumnArticle({},payload){
            let columnID = payload.columnID
            let data = payload.data
            return await Axios.post('v1/admin/columns/'+columnID+"/articles",data)
        },
        async getColumnArticle({},payload){
            let columnID = payload.columnID
            let articleID = payload.articleID
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/columns/${columnID}/articles/${articleID}`))
        },
        async getColumnArticles({},payload){
            let params = payload.params
            let columnID = payload.columnID
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/columns/`+columnID+"/articles",{params:params}))
        },
        async deleteColumnArticle({},payload){
            let columnID = payload.columnID
            let articleID = payload.articleID
            return await Axios.delete(`v1/admin/columns/${columnID}/articles/${articleID}`)
        }
    }
}