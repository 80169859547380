<template>
  <auth-base :title="title">
    <v-form slot="form" v-model="valido">
      <v-text-field
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        :rules="rulesPassword"
        v-model="password"
        class="mb-5"
        v-if="Boolean(verifiedToken)"
        outlined
        single-line
        persistent-hint
        @click:append="showPassword = !showPassword"
        label="Contraseña"
      />
      <v-text-field
        :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword2 ? 'text' : 'password'"
        :rules="rulesRepeatPassword"
        v-model="repeatPassword"
        class="mb-0"
        outlined
        v-if="Boolean(verifiedToken)"
        single-line
        persistent-hint
        @click:append="showPassword2 = !showPassword"
        label="Repita su contraseña"
      />
      <div class="form-text mb-5" v-if="Boolean(message)">{{ message }}</div>
      <v-btn
        :disabled="!valido"
        :loading="loading"
        class="mb-8"
        color="primary"
        v-if="Boolean(verifiedToken)"
        block
        x-large
        tile
        @click="resetPassword"
        >CREAR NUEVA CONTRASEÑA</v-btn
      >
      <div class="back-button" @click="$router.push('/')">
        <v-icon color="green">mdi-keyboard-backspace</v-icon>
        Volver al inicio
      </div>
    </v-form>
  </auth-base>
</template>

<script>
import errors from '../error_codes'
import AuthBase from '../components/auth/AuthBase.vue'
export default {
  components: { AuthBase },
  data () {
    return {
      loading: false,
      valido: false,
      showPassword: false,
      verifiedToken:false,
      showPassword2: false,
      title: 'Restablece tu contraseña',
      token: '',
      message: '',
      password: '',
      repeatPassword: '',
      rulesPassword: [
        password =>
          password.length > 6 ||
          'Debes ingresar una contraseña de más de 6 caracteres.'
      ],
      rulesRepeatPassword: [
        password => password == this.password ||  'Las contraseñas deben coincidir',
        v => !!v || 'Ingrese una contraseña para que coincidan'
      ]
    }
  },
  async beforeMount(){
    let token = this.$route.query.token
    if(token){
      try {
        await this.$store.dispatch('verifyToken',token)
        this.token = token
        this.verifiedToken = true
      } catch (error) {
        this.title = "Token expirado"
        console.log(error.response)
        this.message = error.response.data.error_message
      }
    }else{
      this.$router.push('/')
    }
  },
  methods: {
    async resetPassword () {
      this.message = ''
      this.loading = true
      try {
        await this.$store.dispatch('createPassword', {
          token: this.token,
          new_password: this.password
        })
        this.loading = false
        this.verifiedToken = false
        this.message = "Su contraseña ha sido cambiada"
      } catch (error) {
        console.log(error)
        this.loading = false
        this.message = error.response.data.error_message
      }
    }
  }
}
</script>

<style lang="scss"></style>
