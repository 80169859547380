<template>
    <v-main class="primary full-bg white--text">
        <v-container fill-height>
            <v-layout align-center justify-center>
                <v-flex xs12 sm6 md4 class="text-xs-center">
                    <h2>CARGANDO...</h2>
                    <v-progress-linear indeterminate color="white"/>
                </v-flex>
            </v-layout>
        </v-container>
    </v-main>
</template>

<script>
export default {

}
</script>

<style>

</style>