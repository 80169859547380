import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ForgetPassword from "../views/ForgetPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";

import News from "../views/dashboard/News.vue";
import NewEdit from "../views/dashboard/NewEdit.vue";

import Users from "../views/dashboard/Users.vue";

import Interviews from "../views/dashboard/Interviews.vue";
import InterviewEdit from "../views/dashboard/InterviewEdit.vue";

import Articles from "../views/dashboard/Articles.vue";
import ArticleEdit from "../views/dashboard/ArticleEdit.vue";

import Infographics from "../views/dashboard/Infographics.vue";
import InfographicEdit from "../views/dashboard/InfographicEdit.vue";

import Columns from "../views/dashboard/Columns.vue";
import ColumnEdit from "../views/dashboard/ColumnEdit.vue";

import ColumnArticles from "../views/dashboard/ColumnArticles.vue";
import ColumnArticleEdit from "../views/dashboard/ColumnArticleEdit.vue";

import Nosotros from "../views/dashboard/about/Nosotros.vue";
import JuntaDirectiva from "../views/dashboard/about/JuntaDirectiva.vue";
import ComiteConsultivo from "../views/dashboard/about/ComiteConsultivo.vue";
import ComiteAsuntosAcademicos from "../views/dashboard/about/ComiteAsuntosAcademicos.vue";
import ComiteEventos from "../views/dashboard/about/ComiteEventos.vue";
import ComitePublicaciones from "../views/dashboard/about/ComitePublicaciones.vue";
import ComiteInstitucinal from "../views/dashboard/about/ComiteInstitucinal.vue";
import Egresados from '../views/dashboard/about/Egresados.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    children: [
      {
        path: "/noticias",
        component: News,
      },
      {
        path: "/noticias/:id",
        component: NewEdit,
      },
      {
        path: "/usuarios",
        component: Users,
      },
      {
        path: "/entrevistas",
        component: Interviews,
      },
      {
        path: "/entrevistas/:id",
        component: InterviewEdit,
      },
      {
        path: "/articulos",
        component: Articles,
      },
      {
        path: "/articulos/:id",
        component: ArticleEdit,
      },
      {
        path: "/infografias",
        component: Infographics,
      },
      {
        path: "/infografias/:id",
        component: InfographicEdit,
      },
      {
        path: "/columnas",
        component: Columns,
      },
      {
        path: "/columnas/:id",
        component: ColumnEdit,
      },
      {
        path: "/columnas/:columnID/articulos",
        component: ColumnArticles,
      },
      {
        path: "/columnas/:columnID/articulos/:articleID",
        component: ColumnArticleEdit,
      },
      {
        path: '/web/nosotros',
        component: Nosotros,
      },
      {
        path: '/web/junta-directiva',
        component: JuntaDirectiva
      },
      {
        path: '/web/comite-consultivo',
        component: ComiteConsultivo
      },
      {
        path: '/web/comision/institucional',
        component: ComiteInstitucinal
      },
      {
        path: '/web/comision/eventos',
        component: ComiteEventos
      },
      {
        path: '/web/comision/asuntos-academicos',
        component: ComiteAsuntosAcademicos
      },
      {
        path: '/web/comision/publicaciones',
        component: ComitePublicaciones
      },
      {
        path: '/web/egresados',
        component: Egresados
      }
      
    ],
  },
  {
    path: "/forget-password",
    component: ForgetPassword,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
