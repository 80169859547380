import firebase from "firebase/app";
import 'firebase/auth'
import 'firebase/storage'

var firebaseConfig = {
  apiKey: "AIzaSyDzRguAqtgmW8ksb9Vo28d-zkayuRfoQmc",
  authDomain: "asociacion-tida.firebaseapp.com",
  projectId: "asociacion-tida",
  storageBucket: "asociacion-tida.appspot.com",
  messagingSenderId: "72563977498",
  appId: "1:72563977498:web:0eace02226c4dc5934d0a3",
  measurementId: "G-WWNZWFM837"
};

if (process.env.NODE_ENV === "production") {    
  firebaseConfig = {
    apiKey: "AIzaSyDzRguAqtgmW8ksb9Vo28d-zkayuRfoQmc",
    authDomain: "asociacion-tida.firebaseapp.com",
    projectId: "asociacion-tida",
    storageBucket: "asociacion-tida.appspot.com",
    messagingSenderId: "72563977498",
    appId: "1:72563977498:web:0eace02226c4dc5934d0a3",
    measurementId: "G-WWNZWFM837"
  };
}

firebase.initializeApp(firebaseConfig)