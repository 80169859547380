<template>
  <v-dialog
    v-model="show"
    :max-width="includeBio ? '90%' : '730px'"
    
  >
    <v-card class="modal-tida-member">
      <v-card-title>
        <v-toolbar flat dense>
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="show = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col v-if="!includeBio" cols="12">
            <h3>INFORMACIÓN BÁSICA</h3>
            <v-row>
              <v-col cols="6">
                <image-selector
                  @done="doneImageUpload"
                  :fileURL="member.photo"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  v-model="member.fullname"
                  label="Nombre completo"
                />
                <v-text-field
                  outlined
                  v-if="!hideCharge"
                  v-model="member.charge"
                  label="Cargo actual"
                />
                <v-text-field
                  outlined
                  v-model="member.linkedin"
                  label="Dirección de su LINKEDIN"
                />
              </v-col>
              <v-card-actions class="d-flex justify-center py-5">
                <v-btn @click="done" width="150px" color="primary"
                  >ACEPTAR</v-btn
                >
              </v-card-actions>
            </v-row>
          </v-col>
          <template v-else>
            <v-col cols="5" class="py-0">
              <v-row>
                <v-col cols="6" class="py-0">
                  <h3 class="my-2">INFORMACIÓN BÁSICA</h3>
                  <image-selector
                    @done="doneImageUpload"
                    :fileURL="member.photo"
                  />
                </v-col>
                <v-col cols="6" class="pt-10">
                  <v-text-field
                    outlined
                    v-model="member.fullname"
                    label="Nombre completo"
                  />
                  <v-text-field
                    outlined
                    v-if="!hideCharge"
                    v-model="member.charge"
                    label="Cargo actual"
                  />
                  <v-text-field
                    outlined
                    v-model="member.linkedin"
                    label="Dirección de su LINKEDIN"
                  />
                </v-col>
              </v-row>
              <v-card-actions class="d-flex justify-center py-5">
                <v-btn @click="done" width="150px" color="primary"
                  >ACEPTAR</v-btn
                >
              </v-card-actions>
            </v-col>
            <v-col cols="7" class="py-0">
              <h3 class="my-2">DATOS BIOGRÁFICOS</h3>
              <v-row>
                <v-col cols="6" class="py-0">
                  <v-textarea
                    outlined
                    label="Descripción CORTA"
                    rows="8"
                    v-model="member.bio.description"
                    no-resize
                  />
                  <v-textarea
                    outlined
                    label="Formación complementaria"
                    rows="8"
                    v-model="member.bio.complementary"
                    no-resize
                  />
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-textarea
                    outlined
                    label="Trayectoria profesional"
                    rows="8"
                    v-model="member.bio.career_path"
                    no-resize
                  />
                  <v-textarea
                    outlined
                    label="Experiencia docente"
                    rows="8"
                    v-model="member.bio.experience"
                    no-resize
                  />
                </v-col>
              </v-row>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
      <v-divider />
    </v-card>
  </v-dialog>
</template>

<script>
import ImageSelector from '../shared/ImageSelector.vue'
import slugify from 'slugify'
export default {
  components: { ImageSelector },
  computed: {
    title () {
      return this.action == this.$enums.ACTIONS.CREATE
        ? 'NUEVO MIEMBRO'
        : 'EDITAR MIEMBRO'
    }
  },
  props: {
    includeBio: { required: false, type: Boolean, default: false },
    generateSlug: { required: false, type: Boolean, default: false },
    hideCharge: { required: false, type: Boolean, default: false}
  },
  methods: {
    doneImageUpload (url) {
      this.member.photo = url
    },
    done () {
      if (this.generateSlug) {
        this.member.slug = slugify(this.member.fullname, {
          remove: /[*+~.()'"!:@]/g,
          lower: true
        })
      }
      this.$emit('done', this.member)
      this.show = false
    },
    open (action, member) {
      this.action = action
      if (action == this.$enums.ACTIONS.CREATE) {
        if (this.includeBio) {
          this.member = {
            fullname: '',
            charge: '',
            slug: '',
            photo: null,
            linkedin: '',
            bio: {
              description: '',
              complementary: '',
              career_path: '',
              experience: ''
            }
          }
        } else {
          this.member = {
            fullname: '',
            charge: '',
            slug: '',
            photo: null,
            linkedin: '',
            bio: null
          }
        }
      } else {
        this.member = JSON.parse(JSON.stringify(member))
      }
      this.show = true
    }
  },
  data () {
    return {
      show: false,
      action: this.$enums.ACTIONS.CREATE,
      member: {
        fullname: '',
        charge: '',
        slug: '',
        photo: null,
        linkedin: '',
        bio: {
          description: '',
          complementary: '',
          career_path: '',
          experience: ''
        }
      }
    }
  }
}
</script>

<style lang="scss">
.modal-tida-member {
  textarea {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}
</style>
