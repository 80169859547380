import Vue from "vue";

Vue.prototype.$validators = {
  slug(url) {
    let regex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
    return regex.test(url);
  },
  email(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
};
