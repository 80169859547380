<template>
  <div class="fill-height">
    <drawer-menu />
    <v-main class="fill-height">
      <router-view />
    </v-main>
  </div>
</template>

<script>
import DrawerMenu from '../components/init/DrawerMenu.vue'

export default {
  components: { DrawerMenu },
  data () {
    return {}
  }
}
</script>

<style></style>
