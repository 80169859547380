<template>
  <v-navigation-drawer
    floating
    v-model="drawer"
    permanent
    stateless
    color="primary"
    app
  >
    <v-list dense>
      <v-list-item class="text-center mx-0 mt-5 mb-9 justify-center">
        <img
          width="150px"
          :src="require('../../assets/logo-horizontal-blanco.png')"
        />
      </v-list-item>
      <template v-for="item in generalMenu">
        <template v-if="canAccess(item.roles)">
          <v-list-item
            :key="item.title"
            dense
            :to="item.path"
            color="white"
            v-if="!Boolean(item.items)"
          >
            <v-list-item-action>
              <v-icon>{{ item.action }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="ml-4">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-else
            :key="item.title"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="child.path"
              class="v-list-item__child"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </template>
    </v-list>
    <template v-slot:append>
      <v-list dense>
        <v-list-item @click="logOut">
          <v-list-item-action>
            <v-icon>mdi-power</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data () {
    return {
      generalMenu: [
        {
          title: 'Contenido',
          action: 'mdi-clipboard-text',
          roles: ['admin', 'content-manager'],
          items: [
            { title: 'Noticias', path: '/noticias' },
            { title: 'Entrevistas', path: '/entrevistas' },
            { title: 'Infografías', path: '/infografias' },
            { title: 'Artículos', path: '/articulos' },
            { title: 'Columnas', path: '/columnas' }
          ]
        },
        {
          title: 'Usuarios',
          action: 'mdi-account-multiple',
          roles: ['admin', 'users-manager'],
          path: '/usuarios'
        },
        {
          title: 'Web',
          action: 'mdi-web',
          roles: ['admin', 'web-manager'],
          items: [
            { title: 'Nosotros', path: '/web/nosotros' },
            { title: 'Junta directiva', path: '/web/junta-directiva' },
            { title: 'Comite consultivo', path: '/web/comite-consultivo' },
            { title: 'Comisión de institucionalidad', path: '/web/comision/institucional' },
            { title: 'Comisión de publicaciones', path: '/web/comision/publicaciones' },
            { title: 'Comisión de eventos y economía', path: '/web/comision/eventos' },
            { title: 'Comisión de asuntos académicos', path: '/web/comision/asuntos-academicos' },
            { title: 'Egresados', path: '/web/egresados' },
            
          ]
        },
        {
          title: 'Eventos',
          action: 'mdi-account-box',
          roles: ['admin', 'event-manager'],
          path: '/eventos'
        }
        //{ title: 'Configuración', action: 'mdi-cog', path: '/configuracion' }
      ]
    }
  },
  computed: {
    drawer: {
      set (show) {
        this.$store.commit('changeDrawer', show)
      },
      get () {
        return this.$store.state.drawer
      }
    },
    roles () {
      return this.$store.state.auth.user.roles
    }
  },
  methods: {
    async logOut () {
      await this.$store.dispatch('logOut')
    },
    canAccess (roles) {
      for (let r of roles) {
        if (this.roles.includes(r)) {
          return true
        }
      }
      return false
    }
  }
}
</script>

<style lang="scss">
.v-navigation-drawer {
  .v-list-item:not(.v-list-item__child) {
    color: white !important;
    //padding: 0;
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    line-height: 24px;
    z-index: 4;
    /* identical to box height, or 240% */

    letter-spacing: 1px;
    .top-corner {
      position: absolute;
    }
    .bottom-corner {
      position: absolute;
    }
    &.v-list-item--active:not(.v-list-item__child) {
      &:hover::before {
        opacity: 0 !important;
      }
      .top-corner {
        position: absolute;
        top: -30px;
        right: 0;
        height: 30px;
        width: 30px;
        border-bottom-right-radius: 50%;
        background: 641a54;
        box-shadow: 0px 10px 0px 0px white;
      }
      .bottom-corner {
        position: absolute;
        bottom: -30px;
        right: 0;
        height: 30px;
        width: 30px;
        border-top-right-radius: 50%;
        background: #641a54;
        box-shadow: 10px 0px 0px 0px white;
      }
    }
  }
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: white !important;
    .v-icon {
      color: white !important;
    }
  }
  .v-list-item__child {
    color: white !important;
  }
}
</style>
