<template>
  <v-container fluid class="px-10 infographic-container">
    <v-overlay color="white" :value="generalLoading">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-row wrap>
      <v-col cols="12" class="px-0">
        <v-toolbar flat>
          <v-toolbar-title class="title">
            <v-btn icon small @click="$router.push('/infografias')"
              ><v-icon>mdi-chevron-left</v-icon></v-btn
            >
            <span>{{ title }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="generalLoading" @click="saveItem"
            >GUARDAR</v-btn
          >
        </v-toolbar>
        <v-divider style="border-color: #B9C3C2;" class="" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-form v-model="valido">
          <v-row>
            <v-col cols="6">
              <v-text-field
                dense
                v-model="item.name"
                label="Nombre del módulo"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                v-model="item.slug"
                label="Titulo amigable"
                outlined
              ></v-text-field>
            </v-col>
            <input
              class="d-none"
              accept="image/*"
              type="file"
              ref="inputFile"
              multiple
              @change="newImagesSelected"
            />
            <v-col cols="12">
              <div class="text-subtitle-2">INFOGRAFÍAS
                <v-btn text color="primary" @click="$refs.inputFile.click()"><v-icon>mdi-plus</v-icon>Agregar imágenes</v-btn>
              </div>
              <draggable v-model="item.photos" class="row">
                <v-col cols="4" md="3" v-for="url in item.photos" :key="url">
                  <v-img height="200px" :src="url"> </v-img>
                </v-col>
              </draggable>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <dialog-info ref="dialogInfo" />
    <dialog-links ref="dialogLinks" @actionDone="linkUpdate" />
  </v-container>
</template>

<script>
import DialogConfirm from '../../components/shared/DialogConfirm.vue'
import DialogInfo from '../../components/shared/DialogInfo.vue'
import moment from 'moment'
import DialogLinks from '../../components/shared/DialogLinks.vue'
import PersonInfoForm from '../../components/forms/PersonInfoForm.vue'
import draggable from 'vuedraggable'
export default {
  components: {
    DialogInfo,
    DialogConfirm,
    DialogLinks,
    PersonInfoForm,
    draggable
  },
  computed: {
    formattedDate () {
      if (this.item.publish_date == null) {
        return 'dd/mmm/aaaa'
      }
      return moment(this.item.publish_date).format('DD/MM/YYYY')
    },
    publishDate: {
      set (date) {
        this.item.publish_date = moment(date).toDate()
      },
      get () {
        if (this.item.publish_date == null) {
          return null
        }
        console.log(moment(this.item.publish_date).format('YYYY-MM-DD'))
        return moment(this.item.publish_date).format('YYYY-MM-DD')
      }
    },
    title () {
      return this.action == this.$enums.ACTIONS.CREATE
        ? 'NUEVO MÓDULO'
        : 'EDITAR MÓDULO'
    },
    imageSelected () {
      if (this.newImage != null) {
        return this.newImage
      }
      if (this.item.photo != null) {
        return {
          name: this.item.photo.image_name
        }
      }
      return null
    }
  },
  methods: {
    interviewedPhotoChanged (file) {
      this.interviewedImageFile = file
    },
    async newImagesSelected (e) {
      this.generalLoading = true
      let size = e.target.files.length
      for (let i = 0; i < size; i++) {
        let file = e.target.files[i]
        let url = await this.$files.putFileWithURL(file)
        this.item.photos.push(url)
      }
      this.generalLoading = false
    },
    linkUpdate (result) {
      let link = result.link
      let action = result.action
      let index = result.index
      if (action == this.$enums.ACTIONS.CREATE) {
        this.item.links.push(link)
      }
      if (action == this.$enums.ACTIONS.EDIT) {
        this.$set(this.item.links, index, link)
      }
    },
    newFile (file) {
      this.newImage = file
    },
    async saveItem () {
      this.generalLoading = true
      try {
        if (this.newImage != null) {
          let newID = await this.$files.putFile(this.newImage)
          this.item.photo = {
            image_name: this.newImage.name,
            object_id: newID
          }
        }
        if (this.interviewedImageFile != null) {
          let newID = await this.$files.putFile(this.interviewedImageFile)
          this.item.interviewed.photo = {
            image_name: this.interviewedImageFile.name,
            object_id: newID
          }
        }
        if (this.action == this.$enums.ACTIONS.CREATE) {
          await this.$store.dispatch('postInfographic', this.item)
          this.$toast.success('Infografía creada satisfactoriamente')
        }
        if (this.action == this.$enums.ACTIONS.EDIT) {
          await this.$store.dispatch('putInfographic', this.item)
          this.$toast.success('Infografía actualizada satisfactoriamente')
        }
        this.generalLoading = false
        this.$router.push('/infografias')
      } catch (error) {
        this.$refs.dialogInfo.open(
          'No se pudo completar la operación',
          error.response.data.error_message
        )
      }
      this.generalLoading = false
    }
  },

  data () {
    return {
      loading: false,
      valido: false,
      menu: false,
      interviewedImageFile: null,
      generalLoading: false,
      newImage: null,
      dataTableOptions: {},
      loadingTable: false,
      action: '',
      rules: {
        title: [v => v.length <= 100 || 'Máximo 100 caracteres'],
        slug: [
          v => v.length <= 100 || 'Máximo 100 caracteres',
          v =>
            this.$validators.slug(v) ||
            'El título amigable debe estar en el formato correcto '
        ],
        summary: [v => v.length <= 120 || 'Máximo 120 caracteres']
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link'],
        ['divider'] // remove formatting button
      ],
      editorSettings: {
        modules: {
          htmlEditButton: {},
          divider: {
            cssText: 'border: none;border-bottom: 1px gray inset;'
          }
        }
      },
      item: {
        id: this.$uid(),
        name: '',
        slug: '',
        photos: []
      }
    }
  },
  async beforeMount () {
    this.action = this.$route.query.action
    let id = this.$route.params.id
    this.generalLoading = true
    if (this.action == this.$enums.ACTIONS.CREATE) {
      this.item = {
        id: id,
        name: '',
        slug: '',
        photos: []
      }
    } else {
      let result = await this.$store.dispatch('getInfographic', id)
      this.item = result
    }
    this.generalLoading = false
  }
}
</script>

<style lang="scss">
.infographic-container {
  .image-selector-container {
    border: 1px dotted grey;
    height: 200px;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
