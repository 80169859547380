import Vue from 'vue'

Vue.prototype.$enums = {
    ACTIONS: {
        CREATE : "create",
        EDIT : "edit",
        SHOW:  "show"
    },
    PRODUCTS_ORDER:{
        ALPHABETIC: 'alpha',
        CATEGORY: 'category',
        NEWEST: 'newest',
        FIRST_DISCOUNT: 'first_disc',
        LOWEST_PRICE: 'less_price',
        HIGHEST_PRICE: 'highest_price'
    },
    DOC_TYPES: [
        {value: 'dni', text: 'DNI'},
        {value: 'ruc', text: 'RUC'},
        {value: 'ce', text: 'CE'},
    ],
    ROLES:[
        {value: 'admin', text: 'Administrador'},
        {value: 'users-manager', text: 'Gestor de usuarios'},
        {value: 'content-manager', text : 'Gestor de contenidos'},
        {value: 'event-manager', text: 'Gestor de eventos'}
    ]
}