import Axios from "axios";
import utilities from "./utilities";

export default {
    actions:{
        async putArticle({},payload){
            return await Axios.put('v1/admin/articles',payload)
        },
        async postArticle({},payload){
            return await Axios.post('v1/admin/articles',payload)
        },
        async getArticle({},id){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/articles/${id}`))
        },
        async getArticles({},params){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/articles`,{params:params}))
        },
        async deleteArticle({},id){
            return await Axios.delete(`v1/admin/articles/${id}`)
        }
    }
}