import Axios from "axios";
import utilities from "./utilities";

export default {
    actions:{
        async putNew({},payload){
            return await Axios.put('v1/admin/news',payload)
        },
        async postNew({},payload){
            return await Axios.post('v1/admin/news',payload)
        },
        async getNew({},id){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/news/${id}`))
        },
        async getNews({},params){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/news`,{params:params}))
        },
        async deleteNew({},id){
            return await Axios.delete(`v1/admin/news/${id}`)
        }
    }
}