var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-10 news-view",attrs:{"fluid":""}},[_c('v-overlay',{attrs:{"color":"white","value":_vm.generalLoading}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"64"}})],1),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"title"},[_c('span',{staticClass:"ml-4"},[_vm._v("Usuarios")])]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.moveToCreate}},[_vm._v("NUEVO USUARIO")])],1),_c('v-divider',{staticStyle:{"border-color":"#B9C3C2"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"options":_vm.dataTableOptions,"headers":_vm.headers,"server-items-length":_vm.totalItems,"items":_vm.users,"loading":_vm.loadingTable,"locale":"es-ES","no-data-text":"Aún no hay usuarios registrados","loading-text":"Cargando...","footer-props":{
          'items-per-page-text': 'Usuarios por página',
          'items-per-page-options': [20, 50, 100]
        }},on:{"update:options":function($event){_vm.dataTableOptions=$event},"update:page":_vm.updatePage,"update:items-per-page":_vm.updateItems},scopedSlots:_vm._u([{key:"item.roles",fn:function(ref){
        var item = ref.item;
return _vm._l((item.roles),function(role){return _c('v-chip',{key:role,attrs:{"outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$decoders.roles(role))+" ")])})}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(!item.sent)?_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openEdit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDeleteDialog(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()]}}],null,true)})],1)],1),_c('dialog-info',{ref:"dialogInfo"}),_c('user-drawer',{ref:"userDrawer",on:{"actionDone":_vm.loadItems}}),_c('dialog-confirm',{ref:"dialogConfirm",on:{"actionPressed":_vm.confirmDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }