<template>
  <v-container class="nosotros-view px-10">
    <v-overlay color="white" :value="generalLoading">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-row wrap>
      <v-col cols="12" class="px-0">
        <v-toolbar flat>
          <v-toolbar-title class="title">
            <span>NOSOTROS</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="generalLoading" @click="saveItem"
            >GUARDAR</v-btn
          >
        </v-toolbar>
        <v-divider style="border-color: #B9C3C2;" class="" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0 px-0">
        <v-form v-model="valido">
          <h3>Foto de portada</h3>
          <image-selector
            :fileURL="item.banner"
            @done="finishUpload"
            iconSize="40px"
            title="Seleccione un banner"
            height="250px"
          />
          <v-row class="mt-5">
            <v-col cols="7">
              <v-text-field
                outlined
                label="Título"
                v-model="item.title"
              ></v-text-field>
              <vue-editor
                v-model="item.content"
                :editor-toolbar="customToolbar"
              >
              </vue-editor>
            </v-col>
            <v-col cols="5">
              <v-textarea
                outlined
                label="Descripción SEO"
                no-resize
                rows="10"
                persistent-hint
                hint="Será la descripción que aparecerá en los buscadores como Google"
                v-model="item.seo_description"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <dialog-info ref="dialogInfo" />
  </v-container>
</template>

<script>
import ImageSelector from '../../../components/admin/shared/ImageSelector.vue'
import DialogInfo from '../../../components/shared/DialogInfo.vue'
import { VueEditor } from 'vue2-editor'
export default {
  components: { ImageSelector, DialogInfo, VueEditor },
  data () {
    return {
      valido: false,
      generalLoading: false,
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
      ],
      item: {
        id: '',
        page: 'nosotros',
        banner: null,
        title: '',
        content: '',
        seo_description: ''
      }
    }
  },
  async mounted () {
    this.generalLoading = true
    let data = await this.$store.dispatch('getNosotros')
    console.log(data)
    this.item = data
    this.generalLoading = false
  },
  methods: {
    finishUpload (url) {
      this.item.banner = url
    },
    async saveItem () {
      this.generalLoading = true
      try {
        await this.$store.dispatch('putNosotros', this.item)
        this.$toast.success('Información actualizada satisfactoriamente')
        this.generalLoading = false
      } catch (error) {
        this.$refs.dialogInfo.open(
          'No se pudo completar la operación',
          error.response.data.error_message
        )
      }
      this.generalLoading = false
    }
  }
}
</script>

<style lang="scss"></style>
