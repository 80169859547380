import Axios from "axios";
import utilities from "./utilities";

export default {
    actions:{
        async putUser({},payload){
            return await Axios.put('v1/admin/users',payload)
        },
        async postUser({},payload){
            return await Axios.post('v1/admin/users',payload)
        },
        async getUsers({},params){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/users`,{params:params}))
        },
        async deleteUser({},id){
            return await Axios.delete(`v1/admin/users/${id}`)
        }
    }
}