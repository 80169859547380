<template>
  <v-dialog
    persistent
    max-width="320px"
    overlay-color="#1F3333"
    overlay-opacity="0.9"
    v-model="show"
  >
    <v-card class="dialog-base-card">
      <v-card-title class="dialog-title">{{ title }}</v-card-title>
      <v-card-text class="content pb-0">
        <v-text-field label="Texto" v-model="link.text" outlined />
        <v-text-field label="URL" v-model="link.url" outlined />
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          @click="close"
          color="primary"
          small
          outlined
          primary
          >CANCELAR</v-btn
        >
        <v-btn
          @click="actionDone"
          color="primary"
          class="ml-4"
          small
          primary
          >GUARDAR</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      content: '',
      action: '',
      index: -1,
      link: {
        text: '',
        url: ''
      }
    }
  },
  computed: {
    title () {
      if (this.action == this.$enums.ACTIONS.CREATE) {
        return 'NUEVO ENLACE'
      }
      return 'EDITAR ENLACE'
    }
  },
  methods: {
    actionDone(){
      this.$emit('actionDone',{
        link: this.link,
        action: this.action,
        index: this.index
      })
      this.show = false
    },
    open (action, link, index) {
      this.action = action
      if (this.action == this.$enums.ACTIONS.CREATE) {
        this.link = {
          text: '',
          url: ''
        }
        this.index = -1
      }
      if(this.action == this.$enums.ACTIONS.EDIT){
        this.link = JSON.parse(JSON.stringify(link))
        this.index = index
      }
      this.show = true
    },
    close () {
      this.show = false
    }
  }
}
</script>

<style lang="scss">
.dialog-base-card {
  border-radius: 12px !important;
  .v-card__title {
    font-size: 24px !important;
    line-height: 36px;
    color: #1f3333;
    display: flex;
    justify-content: center;
    padding-top: 40px !important;
    text-align: center;
    word-break: break-word;
  }
  .content {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #1f3333 !important;
  }
  .v-card__actions {
    padding-bottom: 40px !important;
  }
}
</style>
