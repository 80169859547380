<template>
  <v-dialog persistent max-width="320px" overlay-color="#1F3333" overlay-opacity="0.9"  v-model="show">
    <v-card class="dialog-base-card">
      <v-card-title class="dialog-title">{{ title }}</v-card-title>
      <v-card-text class="content" v-html="content"></v-card-text>
      <v-card-actions>
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      title: '',
      content: ''
    }
  },
  methods: {
    open (title, content) {
      this.title = title
      this.content = content
      this.show = true
    },
    close () {
      this.show = false
    }
  }
}
</script>

<style lang="scss">
.dialog-base-card {
    border-radius: 12px !important;
  .v-card__title {
    font-size: 24px !important;
    line-height: 36px;
    color: #1f3333;
    display: flex;
    justify-content: center;
    padding-top: 40px !important;
    text-align: center;
    word-break: break-word;
  }
  .content {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #1f3333 !important;
  }
  .v-card__actions {
    padding-bottom: 40px !important;
  }
}
</style>
