import Axios from "axios";
import utilities from "./utilities";

export default {
    actions:{
        async putColumn({},payload){
            return await Axios.put('v1/admin/columns',payload)
        },
        async postColumn({},payload){
            return await Axios.post('v1/admin/columns',payload)
        },
        async getColumn({},id){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/columns/${id}`))
        },
        async getColumns({},params){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/columns`,{params:params}))
        },
        async deleteColumn({},id){
            return await Axios.delete(`v1/admin/columns/${id}`)
        }
    }
}