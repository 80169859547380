import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import config from "./config";
import news from "./news";
import interviews from "./interviews";
import users from "./users";
import articles from "./articles";
import infographics from "./infographics";
import columns from "./columns";
import columnArticles from './column_articles'
import about from './about';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: true,
    loadingScreen: true,
  },
  mutations: {
    changeDrawer(state, change) {
      if (change == null || change == undefined) {
        state.drawer = !state.drawer;
      } else {
        state.drawer = change;
      }
    },
    setLoadingScreen(state, loadingScreen) {
      state.loadingScreen = loadingScreen;
    },
  },
  actions: {},
  getters: {},
  modules: {
    auth,
    config,
    news,
    users,
    interviews,
    articles,
    infographics,
    columns,
    columnArticles,
    about
  },
});
