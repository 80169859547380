<template>
  <v-container class="nosotros-view px-10">
    <v-overlay color="white" :value="generalLoading">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-row wrap>
      <v-col cols="12" class="px-0">
        <v-toolbar flat>
          <v-toolbar-title class="title">
            <span>EGRESADOS</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="generalLoading" @click="saveItem"
            >GUARDAR</v-btn
          >
        </v-toolbar>
        <v-divider style="border-color: #B9C3C2;" class="" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0 px-0">
        <v-form v-model="valido">
          <v-row>
            <v-col cols="4">
              <h3 class="my-2">
                Presidente del consejo
                <v-icon
                  color="primary"
                  v-if="item.president == null"
                  @click="
                    $refs.modalPresidentTidaMember.open($enums.ACTIONS.CREATE)
                  "
                  >mdi-plus</v-icon
                >
              </h3>
              <tida-member-card
                v-if="item.president != null"
                @deleteMember="item.president = null"
                :member="item.president"
              />
            </v-col>
            <v-col cols="4">
              <h3 class="my-2">
                Secretario general<v-icon
                  color="primary"
                  v-if="item.secretary == null"
                  @click="
                    $refs.modalSecretaryTidaMember.open($enums.ACTIONS.CREATE)
                  "
                  >mdi-plus</v-icon
                >
              </h3>
              <tida-member-card
                v-if="item.secretary != null"
                @deleteMember="item.secretary = null"
                :member="item.secretary"
              />
            </v-col>
            <v-col cols="4">
              <h3 class="my-2">Título SEO</h3>
              <v-text-field
                outlined
                hide-details
                v-model="item.seo_title"
              ></v-text-field>
              <h3 class="my-2">Descripción SEO</h3>
              <v-textarea
                no-resize
                outlined
                rows="4"
                hide-details
                v-model="item.seo_description"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mt-1">
            <v-col cols="12">
              <h3 class="my-2">
                Egresados
              </h3>
            </v-col>
            <v-col cols="6" class=" py-0">
              <v-text-field
                label="Apellidos, Nombres"
                @keypress.enter="addNewName"
                hint="Presione ENTER para agregar al egresado."
                persistent-hint
                outlined
                v-model="newName"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class=" py-0">
              <small>Haga click en los miembros <b>fundadores.</b></small>
            </v-col>
            <v-col
              class="py-0"
              v-for="(member, index) in item.graduates"
              :key="index"
              cols="6"
            >
              <div class=" d-flex justify-space-between align-center egresado-item">
                <v-checkbox
                  v-model="member.is_founder"
                  dense
                  hide-details
                  class="mt-0"
                  :label="member.fullname"
                />
                <v-icon @click="item.graduates.splice(index,1)" small>mdi-close</v-icon>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <dialog-info ref="dialogInfo" />
    <modal-tida-member
      hideCharge
      ref="modalPresidentTidaMember"
      @done="addPresident"
    />
    <modal-tida-member
      hideCharge
      ref="modalSecretaryTidaMember"
      @done="addSecretary"
    />
  </v-container>
</template>

<script>
import ImageSelector from '../../../components/admin/shared/ImageSelector.vue'
import DialogInfo from '../../../components/shared/DialogInfo.vue'
import ModalTidaMember from '../../../components/admin/about/ModalTidaMember.vue'
import TidaMemberCard from '../../../components/admin/about/TidaMemberCard.vue'
export default {
  components: { ImageSelector, DialogInfo, ModalTidaMember, TidaMemberCard },
  data () {
    return {
      valido: false,
      generalLoading: false,
      newName: '',
      item: {
        id: '',
        page: 'egresados',
        president: null,
        secretary: null,
        seo_title: '',
        seo_description: '',
        graduates: []
      }
    }
  },
  async mounted () {
    this.generalLoading = true
    let data = await this.$store.dispatch('getEgresados')
    console.log(data)
    this.item = data
    this.generalLoading = false
  },
  methods: {
    addNewName () {
      this.item.graduates.push({ fullname: this.newName, is_founder: false })
      this.item.graduates.sort((a, b) => a.fullname.localeCompare(b.fullname))
      this.newName = ''
    },
    addPresident (member) {
      this.item.president = member
    },
    addSecretary (member) {
      this.item.secretary = member
    },
    async saveItem () {
      this.generalLoading = true
      try {
        await this.$store.dispatch('putEgresados', this.item)
        this.$toast.success('Información actualizada satisfactoriamente')
        this.generalLoading = false
      } catch (error) {
        this.$refs.dialogInfo.open(
          'No se pudo completar la operación',
          error.response.data.error_message
        )
      }
      this.generalLoading = false
    }
  }
}
</script>

<style lang="scss">
.egresado-item {
  border-bottom: 1px solid rgba(128,128,128,0.4);
  padding: 4px 0;
}
</style>
