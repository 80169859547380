import Axios from "axios";
import utilities from "./utilities";

export default {
    actions:{
        async putInterview({},payload){
            return await Axios.put('v1/admin/interviews',payload)
        },
        async postInterview({},payload){
            return await Axios.post('v1/admin/interviews',payload)
        },
        async getInterview({},id){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/interviews/${id}`))
        },
        async getInterviews({},params){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/interviews`,{params:params}))
        },
        async deleteInterview({},id){
            return await Axios.delete(`v1/admin/interviews/${id}`)
        }
    }
}