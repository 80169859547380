import Axios from "axios";
import utilities from "./utilities";

export default {
    actions:{
        async putNosotros({},payload){
            return await Axios.put('v1/admin/about/nosotros',payload)
        },
        async getNosotros({}){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/about/nosotros`))
        },
        async putJuntaDirectiva({},payload){
            return await Axios.put('v1/admin/about/junta-directiva',payload)
        },
        async getJuntaDirectiva({}){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/about/junta-directiva`))
        },
        async putComiteConsultivo({},payload){
            return await Axios.put('v1/admin/about/comite-consultivo',payload)
        },
        async getComiteConsultivo({}){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/about/comite-consultivo`))
        },
        async getComite({},comite){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/about/comites/${comite}`))
        },
        async putComite({},payload){
            return await Axios.put('v1/admin/about/comites',payload)
        },
        async putEgresados({},payload){
            return await Axios.put('v1/admin/about/egresados',payload)
        },
        async getEgresados({}){
            return await utilities.wrapResponseAndGetData(await Axios.get(`v1/admin/about/egresados`))
        },
    }
}