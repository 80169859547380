import Vue from 'vue'

Vue.prototype.$decoders = {
    /*
        "8. Las unidades de medida a desplegar serán:
        - Kilogramos (kg.)
        - Unidad (ud.)
        - Tonelada (t.)
        - Docena
        - Jaba
        - Caja"							
    */
    units(unit){
        switch(unit){
            case 'kg': return 'Kilogramos'
            case 'ud': return 'Unidades'
            case 't': return 'Toneladas'
            case 'docena': return 'Docenas'
            case 'jaba': return 'Jabas'
            case 'caja': return 'Cajas'
            default: return ''
        }
    },
    paymentMethods(method){
        switch(method){
            case 'card': return 'Tarjeta'
            case 'yape': return 'Yape'
            case 'upon_delivery': return 'Contraentrega'
            default: return ''
        }
    },
    /*
    {value: 'admin', text: 'Administrador'},
        {value: 'users-manager', text: 'Gestor de usuarios'},
        {value: 'content-manager', text : 'Gestor de contenidos'},
        {value: 'event-manager', text: 'Gestor de eventos'}
    */
   roles(role){
    switch(role){
        case 'admin': return 'Administrador/a'
        case 'users-manager': return 'Gestión de usuarios'
        case 'content-manager': return 'Gestión de contenidos'
        case 'event-manager': return 'Gestión de eventos'
        default: return ''
    }
   }
}