<template>
  <v-container fluid class="px-10 news-view">
    <v-overlay color="white" :value="generalLoading">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-row wrap>
      <v-col cols="12">
        <v-toolbar flat>
          <v-toolbar-title class="title">
            <v-btn icon small @click="$router.push(`/columnas`)"
              ><v-icon>mdi-chevron-left</v-icon></v-btn
            >
            <span>{{ title }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="moveToCreate">NUEVO ARTÍCULO</v-btn>
        </v-toolbar>
        <v-divider style="border-color: #B9C3C2;" class="" />
      </v-col>
      <v-col cols="12">
        <v-data-table
          :options.sync="dataTableOptions"
          :headers="headers"
          :server-items-length="totalItems"
          :items="items"
          :loading="loadingTable"
          @update:page="updatePage"
          @update:items-per-page="updateItems"
          locale="es-ES"
          no-data-text="Aún no hay artículos registrados"
          loading-text="Cargando..."
          :footer-props="{
            'items-per-page-text': 'Artículos por página',
            'items-per-page-options': [20, 50, 100]
          }"
        >
          <template v-slot:[`item.title`]="{ item }">
            {{ item.title }}
          </template>
          <template v-slot:[`item.publish_date`]="{ item }">
            {{ item.publish_date }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="!item.sent">
              <v-btn icon @click.stop="openEdit(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click.stop="openDeleteDialog(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <dialog-info ref="dialogInfo" />
    <dialog-confirm @actionPressed="confirmDelete" ref="dialogConfirm" />
  </v-container>
</template>

<script>
import DialogConfirm from '../../components/shared/DialogConfirm.vue'
import DialogInfo from '../../components/shared/DialogInfo.vue'
export default {
  components: { DialogInfo, DialogConfirm },
  data () {
    return {
      loading: false,
      generalLoading: false,
      dataTableOptions: {},
      loadingTable: false,
      items: [],
      toDelete: null,
      totalItems: 0,
      columnName: '',
      column: null,
      columnID: '',
      headers: [
        {
          text: 'Titulo',
          align: 'start',
          sortable: false,
          value: 'title',
          class: 'primary--text'
        },
        {
          text: 'Fecha de publicación',
          align: 'start',
          sortable: false,
          value: 'publish_date',
          class: 'primary--text'
        },
        {
          text: 'N° vistas',
          align: 'start',
          sortable: false,
          value: 'num_views',
          class: 'primary--text'
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'actions',
          class: 'primary--text'
        }
      ],
      search: {
        docsPerPage: 20,
        page: 1
      }
    }
  },
  computed:{
    title(){
      return "Artículos de '"+ this.columnName+"'"
    }
  },
  methods: {
    updatePage (value) {
      this.search.page = value
      this.loadItems()
    },
    updateItems (items) {
      this.search.docsPerPage = items
      this.loadItems()
    },
    moveToCreate () {
      this.$router.push({
        path: `/columnas/${this.columnID}/articulos/${this.$uid()}`,
        query: { action: this.$enums.ACTIONS.CREATE }
      })
    },
    openEdit (item) {
      
      this.$router.push({
        path: `/columnas/${this.columnID}/articulos/${item.id}`,
        query: { action: this.$enums.ACTIONS.EDIT }
      })
    },
    async confirmDelete () {
      try {
        await this.$store.dispatch('deleteColumnArticle',{
          articleID: this.toDelete.id,
          columnID: this.columnID
        })
        this.$refs.dialogConfirm.stopLoading()
        this.$refs.dialogConfirm.close()
        await this.loadItems()
      } catch (error) {
        this.$refs.dialogConfirm.stopLoading()
        this.$refs.dialogConfirm.close()
        this.$refs.dialogInfo.open(
          'Error al eliminar el artículo',
          error.response.data.error_message
        )
      }
    },
    openDeleteDialog (item) {
      this.$refs.dialogConfirm.open(
        'Eliminar artículo',
        'Si elimina el artículo, la información se borrará permanentemente del sistema.'
      )
      this.toDelete = item
    },
    async loadItems () {
      if (!this.loadingTable) {
        try {
          this.loadingTable = true
          let result = await this.$store.dispatch('getColumnArticles', {
            params: this.search,
            columnID: this.columnID
          })
          this.totalItems = result.total_items
          this.items = result.items
          this.loadingTable = false
          this.generalLoading = false
        } catch (error) {
          this.$refs.dialogInfo.open(
            'No se pudieron listar los articulos',
            error.response.data.error_message
          )
          this.loadingTable = false
          this.generalLoading = false
        }
      }
    }
  },
  async beforeMount () {
    this.generalLoading = true
    this.columnID = this.$route.params.columnID
    let column = await this.$store.dispatch('getColumn',this.columnID)
    this.columnName = column.name
    await this.loadItems()
    this.generalLoading = false
  }
}
</script>

<style lang="scss"></style>
