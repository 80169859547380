<template>
  <v-container fluid class="px-10 news-view">
    <v-overlay color="white" :value="generalLoading">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-row wrap>
      <v-col cols="12" class="px-0">
        <v-toolbar flat>
          <v-toolbar-title class="title">
            <v-btn icon small @click="$router.push('/noticias')"
              ><v-icon>mdi-chevron-left</v-icon></v-btn
            >
            <span>{{ title }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="generalLoading" @click="saveItem"
            >GUARDAR</v-btn
          >
        </v-toolbar>
        <v-divider style="border-color: #B9C3C2;" class="" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-form v-model="valido">
          <v-row>
            <v-col cols="8">
              <v-text-field
                dense
                v-model="newItem.title"
                label="Título"
                outlined
              ></v-text-field>
              <v-row>
                <v-col cols="6" class="py-0">
                  <v-text-field
                    dense
                    v-model="newItem.created_by"
                    label="Elaborado por"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-text-field
                    dense
                    v-model="newItem.created_by_subtitle"
                    label="Titulo/rango de la persona que elaboró."
                    hint="Ejm: Miembro de ...."
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="py-0">
                  <v-text-field
                    dense
                    v-model="newItem.summary_by"
                    label="Resumido por"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                <v-text-field
                    dense
                    v-model="newItem.summary_by_subtitle"
                    label="Titulo/rango de la persona que resumió."
                    hint="Ejm: Miembro de ...."
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-textarea
                v-model="newItem.summary"
                label="Sumilla"
                no-resize
                rows="3"
                dense
                outlined
              />
              <vue-editor
                :editorOptions="editorSettings"
                v-model="newItem.content"
                :editor-toolbar="customToolbar"
                useCustomImageHandler
                @image-added="handleImageAdded"
              >
              </vue-editor>
            </v-col>
            <v-col cols="4">
              <v-file-input
                label="Imagen de la noticia"
                outlined
                dense
                prepend-icon=""
                accept="image/*"
                @change="newFile"
                :value="imageSelected"
                :clearable="false"
              >
                {{ newItem.publish_date }}
              </v-file-input>
              <v-text-field
                dense
                v-model="newItem.photo_reference"
                label="Fuente de la imagen"
                outlined
              ></v-text-field>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedDate"
                    label="Fecha de publicación"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="es"
                  first-day-of-week="1"
                  v-model="publishDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cerrar
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-toolbar height="36" flat>
                <v-toolbar-title>
                  <span class="text-h6">Enlaces</span>
                </v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="openNewLink"
                  ><v-icon color="primary">mdi-plus</v-icon></v-btn
                >
              </v-toolbar>
              <v-divider />
              <div v-for="(link, index) in newItem.links" :key="link.url">
                <div class="d-flex justify-space-between align-center">
                  <a :href="link.url" target="_blank">{{ link.text }}</a>
                  <div class="d-flex">
                    <v-btn
                      icon
                      @click="
                        $refs.dialogLinks.open($enums.ACTIONS.EDIT, link, index)
                      "
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    >
                    <v-btn icon @click="newItem.links.splice(index, 1)"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <dialog-info ref="dialogInfo" />
    <dialog-links ref="dialogLinks" @actionDone="linkUpdate" />
  </v-container>
</template>

<script>
import DialogConfirm from '../../components/shared/DialogConfirm.vue'
import DialogInfo from '../../components/shared/DialogInfo.vue'
import { VueEditor, Quill } from 'vue2-editor'

import htmlEditButton from 'quill-html-edit-button'
import quillDivider from 'quill-divider'
import moment from 'moment'
import slugify from 'slugify'
import DialogLinks from '../../components/shared/DialogLinks.vue'
Quill.register({
  'modules/divider': quillDivider,
  'modules/htmlEditButton': htmlEditButton
})
export default {
  components: { DialogInfo, DialogConfirm, VueEditor, DialogLinks },
  computed: {
    formattedDate () {
      if (this.newItem.publish_date == null) {
        return 'dd/mmm/aaaa'
      }
      return moment(this.newItem.publish_date).format('DD/MM/YYYY')
    },
    publishDate: {
      set (date) {
        this.newItem.publish_date = moment(date).toDate()
      },
      get () {
        if (this.newItem.publish_date == null) {
          return null
        }
        console.log(moment(this.newItem.publish_date).format('YYYY-MM-DD'))
        return moment(this.newItem.publish_date).format('YYYY-MM-DD')
      }
    },
    title () {
      return this.action == this.$enums.ACTIONS.CREATE
        ? 'NUEVA NOTICIA'
        : 'EDITAR NOTICIA'
    },
    imageSelected () {
      if (this.newImage != null) {
        return this.newImage
      }
      if (this.newItem.photo != null) {
        return {
          name: this.newItem.photo.image_name
        }
      }
      return null
    }
  },
  methods: {
    async handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      this.generalLoading = true
      let url = await this.$files.putFileWithURL(file)
      Editor.insertEmbed(cursorLocation, 'image', url)
      this.generalLoading = false
      resetUploader()
    },
    linkUpdate (result) {
      let link = result.link
      let action = result.action
      let index = result.index
      if (action == this.$enums.ACTIONS.CREATE) {
        this.newItem.links.push(link)
      }
      if (action == this.$enums.ACTIONS.EDIT) {
        this.$set(this.newItem.links, index, link)
      }
    },
    openNewLink () {
      this.$refs.dialogLinks.open(this.$enums.ACTIONS.CREATE)
    },
    openUpdateLink (link, index) {
      this.$refs.dialogLinks.open(this.$enums.ACTIONS.EDIT, link, index)
    },
    newFile (file) {
      this.newImage = file
    },
    async saveItem () {
      this.generalLoading = true
      try {
        if (this.newImage != null) {
          let newID = await this.$files.putFile(this.newImage)
          this.newItem.photo = {
            image_name: this.newImage.name,
            object_id: newID
          }
        }
        this.newItem.slug = slugify(this.newItem.title,{remove: /[*+~.()'"!:@]/g,lower: true})
        if (this.action == this.$enums.ACTIONS.CREATE) {
          await this.$store.dispatch('postNew', this.newItem)
          this.$toast.success('Noticia creada satisfactoriamente')
        }
        if (this.action == this.$enums.ACTIONS.EDIT) {
          await this.$store.dispatch('putNew', this.newItem)
          this.$toast.success('Noticia actualizada satisfactoriamente')
        }
        this.generalLoading = false
        this.$router.push('/noticias')
      } catch (error) {
        console.log(error)
        this.$refs.dialogInfo.open(
          'No se pudo completar la operación',
          error.response.data.error_message
        )
      }
      this.generalLoading = false
    }
  },

  data () {
    return {
      loading: false,
      valido: false,
      menu: false,
      generalLoading: false,
      newImage: null,
      dataTableOptions: {},
      loadingTable: false,
      action: '',
      rules: {
        title: [v => v.length <= 100 || 'Máximo 100 caracteres'],
        slug: [
          v => v.length <= 100 || 'Máximo 100 caracteres',
          v =>
            this.$validators.slug(v) ||
            'El título amigable debe estar en el formato correcto '
        ],
        summary: [v => v.length <= 120 || 'Máximo 120 caracteres']
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link','image'],
        ['divider'] // remove formatting button
      ],
      editorSettings: {
        modules: {
          htmlEditButton: {},
          divider: {
            cssText: 'border: none;border-bottom: 1px gray inset;'
          }
        }
      },
      newItem: {
        id: this.$uid(),
        title: '',
        summary: '',
        slug: '',
        publish_date: null,
        deleted_at: null,
        deleted_by: null,
        num_views: 0,
        photo: null,
        photo_reference: '',
        content: '',
        created_by: '',
        created_by_subtitle: '',
        summary_by_subtitle: '',
        summary_by: '',
        links: []
      }
    }
  },
  async beforeMount () {
    this.action = this.$route.query.action
    let id = this.$route.params.id
    this.generalLoading = true
    if (this.action == this.$enums.ACTIONS.CREATE) {
      this.newItem = {
        id: id,
        title: '',
        summary: '',
        slug: '',
        publish_date: null,
        deleted_at: null,
        deleted_by: null,
        num_views: 0,
        photo: null,
        photo_reference: '',
        content: '',
        created_by: '',
        summary_by: '',
        created_by_subtitle: '',
        summary_by_subtitle: '',
        links: []
      }
    } else {
      let result = await this.$store.dispatch('getNew', id)
      this.newItem = result
    }
    this.generalLoading = false
  }
}
</script>

<style lang="scss"></style>
