<template>
  <v-container class="junta-directiva-view px-10 pb-15">
    <v-overlay color="white" :value="generalLoading">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-row wrap>
      <v-col cols="12" class="px-0">
        <v-toolbar flat>
          <v-toolbar-title class="title">
            <span>COMITÉ CONSULTIVO</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="generalLoading" @click="saveItem"
            >GUARDAR</v-btn
          >
        </v-toolbar>
        <v-divider style="border-color: #B9C3C2;" class="" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0 px-0">
        <v-form v-model="valido">
          <v-row class="mt-1">
            <v-col cols="12">
              <h3 class="my-2">
                MIEMBROS
                <v-btn
                  @click="$refs.modalTidaMember.open($enums.ACTIONS.CREATE)"
                  icon
                  color="primary"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </h3>
              <v-row>
                <v-col
                  cols="3"
                  v-for="(member, index) in item.members"
                  :key="index"
                >
                  <tida-member-card
                    @deleteMember="deleteMember(index)"
                    :member="member"
                    generateSlug
                    includeBio
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <dialog-info ref="dialogInfo" />
    <modal-tida-member
      includeBio
      ref="modalTidaMember"
      @done="addMember"
      generateSlug
    />
  </v-container>
</template>

<script>
import DialogInfo from '../../../components/shared/DialogInfo.vue'
import ModalTidaMember from '../../../components/admin/about/ModalTidaMember.vue'
import TidaMemberCard from '../../../components/admin/about/TidaMemberCard.vue'
export default {
  components: { DialogInfo, ModalTidaMember, TidaMemberCard },
  data () {
    return {
      valido: false,
      generalLoading: false,
      item: {
        id: '',
        page: 'comite-consultivo',
        banner: null,
        seo_title: '',
        seo_description: '',
        members: []
      }
    }
  },
  async mounted () {
    this.generalLoading = true
    let data = await this.$store.dispatch('getComiteConsultivo')
    console.log(data)
    this.item = data
    this.generalLoading = false
  },
  methods: {
    finishUpload (url) {
      this.item.banner = url
    },
    deleteMember (index) {
      this.item.members.splice(index, 1)
    },
    addMember (member) {
      this.item.members.push(member)
    },
    async saveItem () {
      this.generalLoading = true
      try {
        await this.$store.dispatch('putComiteConsultivo', this.item)
        this.$toast.success('Información actualizada satisfactoriamente')
        this.generalLoading = false
      } catch (error) {
        this.$refs.dialogInfo.open(
          'No se pudo completar la operación',
          error.response.data.error_message
        )
      }
      this.generalLoading = false
    }
  }
}
</script>

<style lang="scss"></style>
