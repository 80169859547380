<template>
  <div class="image-selector" @click="$refs.inputFile.click()">
    <v-overlay color="white" :value="loading">
    <v-progress-circular
      color="primary"
      indeterminate
      size="64"
    ></v-progress-circular>
    </v-overlay>
    <input
      class="d-none"
      accept="image/*"
      type="file"
      ref="inputFile"
      @change="imageChanged"
    />
    <v-img
      :src="fileURL"
      contain
      class="d-flex justify-center align-center"
      :height="height"
      :width="width"
    >
      <div
        v-if="fileURL == null"
        class="text-center d-flex flex-column align-center justify-center"
      >
        <v-icon :size="iconSize">mdi-plus</v-icon>
        <div v-if="!loading" :style="`font-size:${titleFontSize}px`">{{ title }}</div>
        <div v-else :style="`font-size:${titleFontSize}`">Cargando imagen...</div>
      </div>
    </v-img>
  </div>
</template>

<script>
export default {
  props: {
    fileURL: { required: false, default: null, type: String },
    height: { required: false, type: String, default: '350px' },
    width: { required: false, type: String, default: '100%' },
    title: { required: false, type: String, default: 'Seleccione una imagen' },
    titleFontSize: { required: false, type: Number, default: 18 },
    iconSize: { required: false, type: String, default: '18px' }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async imageChanged (e) {
      this.loading = true
      let file = e.target.files[0]
      let URL = await this.$files.putFileWithURL(file)
      console.log(URL)
      this.$emit('done',URL)
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.image-selector {
  border: 1px dotted grey;
  &:hover {
    cursor: pointer;
  }
}
</style>
