<template>
  <v-container fluid class="px-10 news-view">
    <v-overlay color="white" :value="generalLoading">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-row wrap>
      <v-col cols="12" class="px-0">
        <v-toolbar flat>
          <v-toolbar-title class="title">
            <v-btn icon small @click="$router.push('/columnas')"
              ><v-icon>mdi-chevron-left</v-icon></v-btn
            >
            <span>{{ title }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="generalLoading" @click="saveItem"
            >GUARDAR</v-btn
          >
        </v-toolbar>
        <v-divider style="border-color: #B9C3C2;" class="" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-form v-model="valido">
          <v-row>
            <v-col cols="8">
              <v-text-field
                dense
                v-model="item.name"
                label="Título"
                outlined
              ></v-text-field>
              <v-row>
                <v-col cols="12" class="py-0">
                  <div class="text-subtitle-2">Datos del autor</div>
                  <person-info-form :personInfo="item.author" @imageChanged="authorPhotoChanged" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-file-input
                label="Portada de la columna"
                outlined
                dense
                prepend-icon=""
                accept="image/*"
                @change="newFile"
                :value="imageSelected"
                :clearable="false"
              >
                {{ item.publish_date }}
              </v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <dialog-info ref="dialogInfo" />
  </v-container>
</template>

<script>
import DialogConfirm from '../../components/shared/DialogConfirm.vue'
import DialogInfo from '../../components/shared/DialogInfo.vue'
import moment from 'moment'
import PersonInfoForm from '../../components/forms/PersonInfoForm.vue'
import slugify from 'slugify';

export default {
  components: {
    DialogInfo,
    DialogConfirm,
    PersonInfoForm
  },
  computed: {
    formattedDate () {
      if (this.item.publish_date == null) {
        return 'dd/mmm/aaaa'
      }
      return moment(this.item.publish_date).format('DD/MM/YYYY')
    },
    publishDate: {
      set (date) {
        this.item.publish_date = moment(date).toDate()
      },
      get () {
        if (this.item.publish_date == null) {
          return null
        }
        console.log(moment(this.item.publish_date).format('YYYY-MM-DD'))
        return moment(this.item.publish_date).format('YYYY-MM-DD')
      }
    },
    title () {
      return this.action == this.$enums.ACTIONS.CREATE
        ? 'NUEVA COLUMNA'
        : 'EDITAR COLUMNA'
    },
    imageSelected () {
      if (this.newImage != null) {
        return this.newImage
      }
      if (this.item.cover != null) {
        return {
          name: this.item.cover.image_name
        }
      }
      return null
    }
  },
  methods: {
    authorPhotoChanged(file){
      this.authorImageFile = file
    },
    coauthorPhotoChanged(file){
      this.coauthorImageFile = file
    },
    linkUpdate (result) {
      let link = result.link
      let action = result.action
      let index = result.index
      if (action == this.$enums.ACTIONS.CREATE) {
        this.item.links.push(link)
      }
      if (action == this.$enums.ACTIONS.EDIT) {
        this.$set(this.item.links, index, link)
      }
    },
    newFile (file) {
      this.newImage = file
    },
    async saveItem () {
      this.generalLoading = true
      try {
        if (this.newImage != null) {
          let newID = await this.$files.putFile(this.newImage)
          this.item.cover = {
            image_name: this.newImage.name,
            object_id: newID
          }
        }
        if(this.authorImageFile != null) {
          let newID = await this.$files.putFile(this.authorImageFile)
          this.item.author.photo = {
            image_name: this.authorImageFile.name,
            object_id: newID
          }
        }
        this.item.slug = slugify(this.item.name,{remove: /[*+~.()'"!:@]/g,lower: true})
        if (this.action == this.$enums.ACTIONS.CREATE) {
          await this.$store.dispatch('postColumn', this.item)
          this.$toast.success('Columna creada satisfactoriamente')
        }
        if (this.action == this.$enums.ACTIONS.EDIT) {
          await this.$store.dispatch('putColumn', this.item)
          this.$toast.success('Columna actualizada satisfactoriamente')
        }
        this.generalLoading = false
        this.$router.push('/columnas')
      } catch (error) {
        this.$refs.dialogInfo.open(
          'No se pudo completar la operación',
          error.response.data.error_message
        )
      }
      this.generalLoading = false
    }
  },

  data () {
    return {
      loading: false,
      valido: false,
      menu: false,
      authorImageFile: null,
      coauthorImageFile: null,
      generalLoading: false,
      newImage: null,
      dataTableOptions: {},
      loadingTable: false,
      action: '',
      rules: {
        title: [v => v.length <= 100 || 'Máximo 100 caracteres'],
        slug: [
          v => v.length <= 100 || 'Máximo 100 caracteres',
          v =>
            this.$validators.slug(v) ||
            'El título amigable debe estar en el formato correcto '
        ],
        summary: [v => v.length <= 120 || 'Máximo 120 caracteres']
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link'],
        ['divider'] // remove formatting button
      ],
      editorSettings: {
        modules: {
          htmlEditButton: {},
          divider: {
            cssText: 'border: none;border-bottom: 1px gray inset;'
          }
        }
      },
      item: {
        id: this.$uid(),
        name: '',
        slug: '',
        author: {
          fullname: '',
          bio: '',
          photo: null
        },
        cover: null
      }
    }
  },
  async beforeMount () {
    this.action = this.$route.query.action
    let id = this.$route.params.id
    this.generalLoading = true
    if (this.action == this.$enums.ACTIONS.CREATE) {
      this.item = {
        id: id,
        name: '',
        slug: '',
        author: {
          fullname: '',
          bio: '',
          photo: null
        },
        cover: null
      }
    } else {
      let result = await this.$store.dispatch('getColumn', id)
      this.item = result
    }
    this.generalLoading = false
  }
}
</script>

<style lang="scss"></style>
